/* Footer components */

/* stylelint-disable */
.ct__kipsu--new {
	bottom: 1rem;
	margin: 0 !important;
	position: absolute;
	width: 100px;
	z-index: 101
}

.ct__kipsu--new.lang__french {
	right: 0 !important;
	-webkit-transform: translateX(calc(-100% - 50px));
	transform: translateX(calc(-100% - 50px));
}

.ct__kipsu--new.lang__english {
	right: 0 !important;
	-webkit-transform: translateX(calc(-100% - 50px));
	transform: translateX(calc(-100% - 50px));
}

.ct__footer {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 3rem 0
}

.ct__footer.ct__footer--default {
	background: #f2f2f2;
	color: #25272a;
	font-size: 1rem;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul {
	display: -ms-grid;
	display: grid;
	grid-gap: 3rem 20px;
	gap: 3rem 20px;
	-ms-grid-columns: 146px 146px;
	grid-template-columns: 146px 146px;
	text-align: center
}

.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul li a {
	font-size: 16px;
	font-weight: 600;
	position: relative;
}

.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul li a::after {
	background: #25272a;
	content: "";
	display: block;
	height: 1px;
	position: absolute;
	right: 50%;
	-webkit-transform: translate(50%, 0.2em);
	transform: translate(50%, 0.2em);
	-webkit-transition: width ease-in-out 250ms;
	transition: width ease-in-out 250ms;
	width: 0;
}

.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul li a:hover::after {
	width: 100%;
}

.ct__footer.ct__footer--default .ct__footer--subtitle {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 0.667rem;
}

.ct__footer.ct__footer--default .ct__footer--subtitle a {
	color: #25272a;
	font-size: 1rem;
	font-size: 16px;
	font-weight: 600;
	text-decoration: underline
}

.ct__footer.ct__footer--default .ct__footer--subtitle a span {
	color: #25272a;
	font-size: 1rem;
	font-size: 16px;
	font-weight: 600;
}

.ct__footer.ct__footer--default .ct__footer--subtitle a:hover {
	text-decoration: none;
}

.ct__footer.ct__footer--default .ct__footer--information {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--brand {
	margin-bottom: 3rem
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--brand img {
	height: 54px;
	-o-object-fit: contain;
	object-fit: contain;
	width: 104px;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--brand .ct__footer--logo.mall-logo__southcentre,
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--brand .ct__footer--logo.mall-logo__uppercanada {
	height: auto;
	width: 210px;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--brand .ct__footer--logo.mall-logo__galeries {
	height: auto;
	width: 160px;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 3rem;
	text-align: center
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language a,
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .lang_link {
	color: #25272a;
	font-size: 16px;
	font-weight: 500;
	margin: 0 20px;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language a.active, .ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .lang_link.active {
	font-weight: 600;
	text-decoration: underline;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .wpml-ls-legacy-list-horizontal {
	padding: 0 !important
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .wpml-ls-legacy-list-horizontal a {
	color: #25272a !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	margin: 0 20px !important;
	padding: 0 !important;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .wpml-ls-legacy-list-horizontal .wpml-ls-current-language {
	text-decoration: underline !important
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language .wpml-ls-legacy-list-horizontal .wpml-ls-current-language a {
	font-weight: 600 !important;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--hours {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 3rem;
	text-align: center
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--hours .ct__footer--subtitle {
	margin-bottom: 1rem;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 3rem;
	max-width: 250px;
	text-align: center
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address .ct__footer--subtitle {
	margin-bottom: 0.6rem;
}
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address h3 {
	margin-bottom: 4px;
	margin-top: -6px;
}
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address span {
	font-size: 16px !important;
}
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--hours svg, .ct__footer.ct__footer--default .ct__footer--information .ct__footer--address svg {
	margin-bottom: 1rem;
	-webkit-transition: margin-left 0.2s ease-in-out;
	transition: margin-left 0.2s ease-in-out
}
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--hours svg:hover, .ct__footer.ct__footer--default .ct__footer--information .ct__footer--address svg:hover {
	margin-left: 10px;
}
.ct__footer.ct__footer--default .ct__footer--information .ct__footer--hours:hover svg, .ct__footer.ct__footer--default .ct__footer--information .ct__footer--address:hover svg {
	margin-left: 10px;
}

.ct__footer.ct__footer--default .ct__footer--information .ct__footer--social {
	display: none;
}
.ct__footer.ct__footer--default .ct__menu--footer {
	margin-bottom: 3rem;
}
.ct__footer.ct__footer--default .ct__footer--social {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 40px;
	width: 100%
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons {
	display: -webkit-grid;
	display: -ms-grid;
	display: grid;
	grid-gap: 15px !important;
	gap: 15px !important;
	height: auto;
	margin: 5px auto;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.one {
	-ms-grid-columns: 30px;
	grid-template-columns: 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.two {
	-ms-grid-columns: 30px 30px;
	grid-template-columns: 30px 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.three {
	-ms-grid-columns: 30px 30px 30px;
	grid-template-columns: 30px 30px 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.four {
	-ms-grid-columns: 30px 30px 30px 30px;
	grid-template-columns: 30px 30px 30px 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.five {
	-ms-grid-columns: 30px 30px 30px 30px 30px;
	grid-template-columns: 30px 30px 30px 30px 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.six {
	-ms-grid-columns: 30px 30px 30px 30px 30px 30px;
	grid-template-columns: 30px 30px 30px 30px 30px 30px;
}
.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons.seven {
	-ms-grid-columns: 30px 30px 30px 30px 30px 30px 30px;
	grid-template-columns: 30px 30px 30px 30px 30px 30px 30px;
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: auto
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons a svg {
	height: 100%;
	margin: auto;
	max-height: 20px;
	width: auto;
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons .ct__social-icon {
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	color: #25272a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
		-ms-flex-direction: row;
			flex-direction: row;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons .ct__social-icon svg {
	height: 100%;
	width: auto;
}

.ct__footer.ct__footer--default .ct__footer--social .ct__footer--icons .ct__social-icon a:hover svg {
	opacity: 0.4;
}

.ct__footer.ct__footer--secondary {
	background: #b0aeaf;
	color: #25272a;
	font-size: 0.9rem;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}
.ct__footer.ct__footer--secondary a {
	color: #25272a;
	font-size: 0.9rem;
}
.ct__footer.ct__footer--secondary .footer__logo {
	margin-bottom: 3rem;
}
.ct__footer.ct__footer--secondary .ct__links--wrapper {
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
}

@media screen and ( min-width: 768px ) {

	.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul {
		display: -ms-grid;
		display: grid;
		grid-gap: 3rem 20px;
		gap: 3rem 20px;
		-ms-grid-columns: (146px)[3];
		grid-template-columns: repeat(3, 146px);
		text-align: center;
	}
}
@media screen and (min-width: 1200px) {

	.ct__kipsu--new {
		width: 300px;
		z-index: 9999
	}

	.ct__kipsu--new.lang__french {
		right: 0 !important;
		-webkit-transform: translateX(calc(-100% + 350px));
				transform: translateX(calc(-100% + 350px));
	}

	.ct__kipsu--new.lang__english {
		right: 0 !important;
		-webkit-transform: translateX(calc(-100% + 350px));
				transform: translateX(calc(-100% + 350px));
	}

	.ct__footer.ct__footer--default {
		-webkit-box-align: start;
			-ms-flex-align: start;
				align-items: flex-start;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
			-ms-flex-direction: row;
				flex-direction: row;
		padding: 4rem 4.5rem
	}

	.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul {
		grid-gap: 3rem 5rem;
		gap: 3rem 5rem;
		-ms-grid-columns: (1fr)[3];
		grid-template-columns: repeat(3, 1fr);
		text-align: left;
	}

	.ct__footer.ct__footer--default .ct__menu.ct__menu--footer li a::after {
		right: auto;
		-webkit-transform: translateY(0.2em);
				transform: translateY(0.2em);
	}

	.ct__footer.ct__footer--default .ct__footer--information {
		-webkit-box-align: start;
			-ms-flex-align: start;
				align-items: flex-start;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
			-ms-flex-direction: row;
				flex-direction: row;
		-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		-webkit-box-pack: justify;
			-ms-flex-pack: justify;
				justify-content: space-between

	}

	.ct__footer.ct__footer--default .ct__footer--information .ct__footer--language {
		display: none;
	}

	.ct__footer.ct__footer--default .ct__footer--information .ct__footer--social {
		-webkit-box-align: center;
			-ms-flex-align: center;
				align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 100%;
			-ms-flex: 100%;
				flex: 100%;
		-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
		-webkit-box-pack: end;
			-ms-flex-pack: end;
				justify-content: flex-end;
		margin-right: 0;
		padding-top: 40px;
		width: 100%;
	}
	
	.ct__footer.ct__footer--default .ct__footer--information .open__hours {
		height: 95px;
		margin: 0 3rem;
		min-width: 135px;
		text-align: left
	}
					
	.ct__footer.ct__footer--default .ct__footer--information .open__hours a {
		margin-bottom: 0;
	}

	.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address {
		height: 95px;
		max-width: 275px;
		text-align: left
	}
					
	.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address a {
		margin-bottom: 0;
	}
			
	.ct__footer.ct__footer--default .ct__footer--menus {
		margin-left: clamp(6rem, 8rem, 10rem)
	}

	.ct__footer.ct__footer--default .ct__footer--menus .ct__footer--social {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: none;
		-webkit-box-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		margin-right: 0
	}

	.ct__footer.ct__footer--default .ct__footer--menus .ct__footer--social .ct__footer--icons {
		margin: 0 0 0 auto;
	}

	.ct__footer.ct__footer--secondary {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 2rem 4rem
	}

	.ct__footer.ct__footer--secondary .footer__logo {
		margin: 0;
	}

	.ct__footer.ct__footer--secondary .ct__links--wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1663px !important;
		width: 100%;
	}
}
@media screen and (min-width: 1440px) {

	.ct__footer.ct__footer--default .ct__footer--information .ct__footer--address {
		height: 95px;
	}
}

@media screen and ( min-width: 1563px ) {

	.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul {
		grid-gap: 3rem 7rem;
		gap: 3rem 7rem;
		-ms-grid-columns: (1fr)[4];
		grid-template-columns: repeat(4, 1fr);
		text-align: left;
	}
}

@media screen and ( min-width: 1767px ) {
		
	.ct__footer.ct__footer--default .ct__menu.ct__menu--footer ul {
		-ms-grid-columns: (1fr)[5];
		grid-template-columns: repeat(5, 1fr);
	}

	.ct__footer.ct__footer--default .ct__footer--menus .ct__footer--social {
		margin-right: 20px;
	}
}

@media (min-width: 1960px) {

	.ct__footer {
		margin: 0 auto;
		max-width: 1663px;
	}

	.ct__footer.ct__footer--default,
	.ct__footer.ct__footer--secondary {
		max-width: 100%;
		position: relative;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		width: 100%
	}

	.ct__footer.ct__footer--default::after, .ct__footer.ct__footer--secondary::after {
		content: " ";
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transform: translateZ(-1px);
		transform: translateZ(-1px);
		width: 100%;
		z-index: -2;
	}

	.ct__footer.ct__footer--default {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
		padding-left: 0 !important;
		padding-right: 0 !important
	}

	.ct__footer.ct__footer--default::after {
		background: #f2f2f2;
	}

	.ct__footer.ct__footer--secondary {
		padding-left: 0 !important;
		padding-right: 0 !important
	}

	.ct__footer.ct__footer--secondary::after {
		background: #b0aeaf;
	}
}
/* stylelint-enable */
